/* home.css */

/* Existing styles ... */

/* Best Player Card Styles */
.best-player-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.best-player-card {
  animation: fadeIn 1s ease-in-out;
  position: relative;
  overflow: hidden;
  border: 3px solid transparent;
}

.best-player-card::before {
  content: '';
  position: absolute;
  top: -2px; left: -2px; bottom: -2px; right: -2px;
  background: linear-gradient(45deg, red, orange, yellow, orange, red);
  background-size: 400% 400%;
  animation: gradientAnimation 5s ease infinite;
  z-index: -1;
}

.best-player-card::after {
  content: '';
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
  background: #2A2126;
  z-index: -2;
}

@keyframes gradientAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Adjust h1 color */
h1 {
  color: #FDF2E7;
}
